<template>
  <CTabs variant="tabs" :active-tab="0" addTabsWrapperClasses="tab-info" add-tab-classes="LogContent">
    <CTab v-if="ShowActivityLog">
      <template #title>
        {{ $t('Order.ActivityLog') }}
      </template>
      <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
        <div v-for="(Data, Index) in OrderData.Log" :key="Data.EventTime + '-' + Index" class="vertical-timeline-item vertical-timeline-element">
          <div>
            <span class="vertical-timeline-element-icon bounce-in">
              <i class="badge badge-dot badge-dot-xl" :class="[{ 'badge-warning': Data.Status === 'N', 'badge-danger': Data.Status === 'X', 'badge-success': Data.Status !== 'X' && Data.Status !== 'N' }]"></i>
            </span>
            <div class="vertical-timeline-element-content bounce-in">
              <h5 class="timeline-title">{{ $t('Order.ActivityLogEvent.' + Data.Event) }}</h5>
              <p v-if="Data.Event === 'CreateOrder'">
                {{ Data.UserID !== '0' ? $t('Order.ActivityLogEvent.MemberCreateOrder') : $t('Order.ActivityLogEvent.CustomCreateOrder') }} ({{ $t('Order.ActivityLogEvent.IP') }}: {{ Data.ClientIP }})
              </p>
              <p v-else-if="Data.Event === 'CreateLogistics'">
                <span class="d-block">{{ Data.Message }}</span>
              </p>
              <p v-else>
                {{ $t('Order.ActivityLogEvent.' + Data.Origin + Data.Event) }}:
                <span v-if="Data.Event === 'ChangePaymentStatus'">{{ $t('Order.PaymentStatusList.' + Data.Status) }}</span>
                <span v-if="Data.Event === 'ChangeLogisticsStatus'">{{ $t('Order.LogisticsStatusList.' + Data.Status) }}</span>
                <span v-if="Data.Event === 'ChangeOrderStatus'">{{ $t('Order.OrderStatusList.' + Data.Status) }}</span>
                <span v-if="Data.Event === 'PrintShipmentList'">{{ $t('Order.LabelStatusList.' + Data.Status) }}</span>
                <span class="d-block">{{ Data.Message }}</span>
              </p>
              <p class="small font-italic text-right">{{ $t('Order.ActivityLogEvent.SystemTime') }}: {{ $dayjs(Data.EventTime).format('YYYY-MM-DD HH:mm:ss') }}</p>
              <span class="vertical-timeline-element-date">{{ $dayjs(Data.EventTime).format('MMM-DD') }}</span>
            </div>
          </div>
        </div>
      </div>
    </CTab>
    <CTab v-if="ShowPaymentLog">
      <template #title>
        {{ $t('Order.PaymentLog') }}
      </template>
      <CAlert v-if="TransactionLog.length <= 0" color="info">
        {{ $t('Order.ActivityLogEvent.NoPaymentLog') }}
      </CAlert>
      <div v-else class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
        <div v-for="(Data, Index) in TransactionLog" :key="Data.UpdateTime._seconds + '-' + Index" class="vertical-timeline-item vertical-timeline-element">
          <div>
            <span class="vertical-timeline-element-icon bounce-in">
              <i class="badge badge-dot badge-dot-xl badge-info"></i>
            </span>
            <div class="vertical-timeline-element-content bounce-in">
              <h5 class="timeline-title">{{ $t('Order.ActivityLogEvent.TransactionResult') }}</h5>
              <p class="small">
                <b>{{ Data.Message }}</b> ({{ $t('Order.ActivityLogEvent.MessageCode') }}: {{ Data.Code }})<br>
                <b>{{ $t('Order.ActivityLogEvent.PayCenter') + $t('Order.OrderNum') }}:</b> {{ Data.OrderNo }}<br>
                <b>{{ $t('Payment.System') + $t('Order.OrderNum') }}:</b> {{ Data.TradeNo }}
              </p>
              <p class="small font-italic text-right">{{ $t('Order.ActivityLogEvent.SystemTime') }}: {{ $dayjs(Data.UpdateTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}</p>
              <span class="vertical-timeline-element-date">{{ $dayjs(Data.UpdateTime._seconds * 1000).format('MMM-DD') }}</span>
            </div>
          </div>
        </div>
      </div>
    </CTab>
    <CTab v-if="ShowLogisticsLog">
      <template #title>
        {{ $t('Order.LogisticsLog') }}
      </template>
      <CAlert v-if="LogisticsLog.length <= 0" color="info">
        {{ $t('Order.ActivityLogEvent.NoShipmentLog') }}
      </CAlert>
      <div v-else class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
        <div v-for="(Data, Index) in LogisticsLog" :key="Data.UpdateTime._seconds + '-' + Index" class="vertical-timeline-item vertical-timeline-element">
          <div>
            <span class="vertical-timeline-element-icon bounce-in">
              <i class="badge badge-dot badge-dot-xl badge-info"></i>
            </span>
            <div class="vertical-timeline-element-content bounce-in">
              <h5 class="timeline-title">{{ (OrderData.Logistics.System !== 'CUSTOM' && OrderData.Logistics.System !== 'CUSTOM-LOGISTICS' ? $t('Logistics.LogisticsSystem.' + OrderData.Logistics.System) : OrderData.LogisticsData.Method) + $t('Order.ActivityLogEvent.ShipmentProcessResult') }}</h5>
              <p class="small">
                <b>{{ Data.Message }}</b> ({{ $t('Order.ActivityLogEvent.MessageCode') }}: {{ Data.Code }})<br>
                <b>{{ $t('Order.ActivityLogEvent.PayCenter') + $t('Order.OrderNum') }}:</b> {{ Data.OrderNo }}<br>
                <b>{{ $t('Logistics.LogisticsSystem.' + OrderData.Logistics.System) + $t('Order.OrderNum') }}:</b> {{ Data.TradeNo }}
              </p>
              <p class="small font-italic text-right">{{ $t('Order.ActivityLogEvent.SystemTime') }}: {{ $dayjs(Data.UpdateTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}</p>
              <span class="vertical-timeline-element-date">{{ $dayjs(Data.UpdateTime._seconds * 1000).format('MMM-DD') }}</span>
            </div>
          </div>
        </div>
      </div>
    </CTab>
  </CTabs>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    OrderData: {
      type: Object,
      default: () => {
        return []
      }
    },
    ShowActivityLog: {
      type: Boolean,
      default: true
    },
    ShowPaymentLog: {
      type: Boolean,
      default: true
    },
    ShowLogisticsLog: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      TransactionLog: [],
      LogisticsLog: []
    }
  },
  mounted() {
    return Promise.all([
      this.GetTransactionLog(),
      this.GetLogisticsLog()
    ])
  },
  methods: {
    GetTransactionLog () {
      return this.$store.dispatch('InnerRequest', {
        url: '/order/transactionLog',
        method: 'post',
        data: {
          // TransactionOrderNo: this.OrderData.Payment.Detail ? this.OrderData.Payment.Detail.TransactionOrderNo : null,
          OrderNum: this.OrderData.OrderNum,
          Payment: this.OrderData.Payment
        }
      }).then(({data}) => {
        this.Loading = false
        this.TransactionLog = data
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetLogisticsLog () {
      if (typeof this.OrderData.LogisticsData === 'object') {
        return this.$store.dispatch('InnerRequest', {
          url: '/order/logisticsLog',
          method: 'post',
          data: {
            OrderNo: this.OrderData.LogisticsData.OrderNo,
            Logistics: this.OrderData.Logistics
          }
        }).then(({data}) => {
          this.Loading = false
          this.LogisticsLog = data
        }).catch((err) => {
          this.Loading = false
          throw err
        })
      } else {
        return Promise.resolve(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-70 {
  margin-top: 70px
}

.mb-70 {
  margin-bottom: 70px
}

.vertical-timeline {
  width: 100%;
  position: relative;
  padding: 1.5rem 0 1rem
}

.vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 67px;
  height: 100%;
  width: 4px;
  background: #e9ecef;
  border-radius: .25rem
}

.vertical-timeline-element {
  position: relative;
  margin: 0 0 1rem
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  animation: cd-bounce-1 .8s
}

.vertical-timeline-element-icon {
  position: absolute;
  top: 2px;
  left: 60px
}

.vertical-timeline-element-icon .badge-dot-xl {
  box-shadow: 0 0 0 5px #fff
}

.badge-dot-xl {
  width: 18px;
  height: 18px;
  position: relative
}

.badge:empty {
  display: inline-block
}

.badge-dot-xl::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: .25rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 0 0 -5px;
  background: #fff
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 90px;
}

.vertical-timeline-element-content .timeline-title {
  text-transform: uppercase;
  margin: 0 0 .5rem;
  font-weight: bold
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  display: block;
  position: absolute;
  left: -90px;
  top: 2px;
  padding-right: 10px;
  text-align: right;
  color: #adb5bd;
  font-size: .7619rem;
  white-space: nowrap
}

.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both
}

.LogContent {
  max-height: 740px;
  overflow: auto;
}
</style>
